<template>
  <div class="antialiased bg-gradient-to-br from-green-100 to-white">
    <div class="container px-6 mx-auto">
      <div
        class="flex flex-col md:min-h-screen text-center md:text-left md:flex-row justify-evenly md:items-center"
      >
        <div class="w-full my-4 md:w-full lg:w-full mx-auto md:mx-4">
          <div class="bg-white p-5 flex flex-col w-full shadow-xl rounded-xl">
            <h2 class="text-2xl font-bold text-gray-800 text-left">
              Pengaturan Cover
            </h2>
            <div
              class="w-full grid md:grid-cols-2 grid-cols-1 md:gap-2 text-left"
            >
              <div class="flex flex-col w-full my-1">
                <label for="kertas" class="text-gray-500">Kertas</label>
                <select
                  id="kertas"
                  v-model="kertas"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="a4">A4</option>
                  <option value="legal">Legal</option>
                </select>
              </div>
              <div class="flex flex-col w-full my-1">
                <label for="orientation" class="text-gray-500"
                  >Orientation</label
                >
                <select
                  id="orientation"
                  v-model="orientation"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="portrait" selected="true">portrait</option>
                  <option value="landscape">landscape</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full my-4 md:w-full lg:w-full mx-auto md:mx-0">
          <div class="bg-white p-5 flex flex-col w-full shadow-xl rounded-xl">
            <h2 class="text-2xl font-bold text-gray-800 text-left">
              Identitas Cover
            </h2>
            <div
              class="w-full grid md:grid-cols-2 grid-cols-1 md:gap-2 text-left"
            >
              <div class="flex flex-col w-full my-1">
                <label for="nama_sekolah" class="text-gray-500"
                  >Nama Sekolah</label
                >
                <input
                  type="text"
                  id="nama_sekolah"
                  v-model="nama_sekolah"
                  placeholder="Mis. SDN Pondok Ranji 02"
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                />
              </div>
              <div class="flex flex-col w-full my-1">
                <label for="judul_cover" class="text-gray-500"
                  >Judul Cover</label
                >
                <input
                  type="text"
                  id="judul_cover"
                  v-model="judul1"
                  placeholder="Mis. Rencana Pelaksanaan Pembelajaran"
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                />
              </div>
              <div class="flex flex-col w-full my-1">
                <label for="sub_judul" class="text-gray-500"
                  >Sub Judul Cover</label
                >
                <input
                  type="text"
                  id="sub_judul"
                  v-model="judul2"
                  placeholder="Mis. Tema 3"
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                />
              </div>
              <div class="flex flex-col w-full my-1">
                <label for="kelas" class="text-gray-500">Kelas</label>
                <input
                  type="text"
                  id="kelas"
                  v-model="kelas"
                  placeholder="Mis. VI (Enam)"
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                />
              </div>
              <div class="flex flex-col w-full my-1">
                <label for="semester" class="text-gray-500">Semester</label>
                <input
                  type="text"
                  id="semester"
                  v-model="semester"
                  placeholder="Mis. Ganjil"
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                />
              </div>
              <div class="flex flex-col w-full my-1">
                <label for="semester" class="text-gray-500">Tahun Ajaran</label>
                <input
                  type="text"
                  id="semester"
                  v-model="tahunAjaran"
                  placeholder="Mis. 2022/2023"
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                />
              </div>
            </div>
            <h2 class="text-2xl font-bold text-gray-800 text-left mt-2">
              Identitas Penyusun
            </h2>
            <div
              class="w-full grid md:grid-cols-1 grid-cols-1 md:gap-2 text-left"
            >
              <div class="flex flex-col w-full my-1">
                <label for="nama_penyusun" class="text-gray-500"
                  >Nama Penyusun</label
                >
                <input
                  type="text"
                  id="nama_penyusun"
                  v-model="nama"
                  placeholder="Mis. Mumin Abdilah"
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                />
              </div>
            </div>
            <div class="w-full grid grid-cols-2 md:gap-2 gap-2 text-left">
              <div class="flex flex-col w-full my-1">
                <label for="jenis_ni" class="text-gray-500">NIP/NIPPPK</label>
                <select
                  id="countries"
                  v-model="jenis_ni"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="NIP" selected="true">NIP</option>
                  <option value="NIPPPK">NIPPPK</option>
                </select>
              </div>
              <div class="flex flex-col w-full my-1">
                <label for="nomor_induk" class="text-gray-500"
                  >Nomor Induk</label
                >
                <input
                  type="text"
                  id="nomor_induk"
                  v-model="nomor_induk"
                  placeholder="Mis. 1989xxxx"
                  class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
                />
              </div>
            </div>
            <div id="button" class="flex flex-col w-full my-1">
              <div
                @click="generatePDF()"
                class="w-full py-4 bg-green-600 rounded-lg text-green-100 cursor-pointer"
              >
                <div class="flex flex-row items-center justify-center">
                  <div class="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="w-6 h-6"
                    >
                      <polyline points="8 17 12 21 16 17"></polyline>
                      <line x1="12" y1="12" x2="12" y2="21"></line>
                      <path
                        d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"
                      ></path>
                    </svg>
                  </div>
                  <div class="font-bold">Cetak Cover</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="judul1 + kelas + nama"
      :pdf-quality="2"
      :manual-pagination="false"
      :pdf-content-width="widthValue"
      :pdf-format="kertas"
      :pdf-orientation="orientation"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <div
          :style="{ 'margin-top': marginTop + 'px' }"
          style="position: relative"
        >
          <!-- PDF Content Here -->
          <CoverContent>
            <template v-slot:nama_sekolah>
              {{ nama_sekolah }}
            </template>
            <template v-slot:judul1>
              {{ judul1 }}
            </template>
            <template v-slot:tahun_ajaran>
              {{ tahunAjaran }}
            </template>
            <template v-slot:judul2>
              {{ judul2 }}
            </template>
            <template v-slot:kelas>
              {{ kelas }}
            </template>
            <template v-slot:semester>
              {{ semester }}
            </template>
            <template v-slot:nip>
              {{ nomor_induk }}
            </template>
            <template v-slot:jenis_nip>
              {{ jenis_ni }}
            </template>
            <template v-slot:nama_lengkap>
              {{ nama }}
            </template>
          </CoverContent>
        </div>
      </template>
    </vue3-html2pdf>
  </div>
</template>
<script>
import Vue3Html2pdf from "vue3-html2pdf";
import CoverContent from "@/components/CoverContent.vue";
export default {
  data() {
    return {
      judul1: "",
      nama_sekolah: "",
      tahunAjaran: "",
      judul2: "",
      kelas: "",
      semester: "",
      nama: "",
      nomor_induk: "",
      jenis_ni: "NIP",
      orientation: "portrait",
      kertas: "a4",
      widthValue: "",
      marginTop: 200,
    };
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    generatePDF() {
      if (this.nama_sekolah == "") {
        console.log("nama sekolah kosong!");
      }
      this.$refs.html2Pdf.generatePdf();
    },
  },
  watch: {
    orientation: function () {
      if (this.orientation == "landscape") {
        this.widthValue = "1120px";
        this.marginTop = 80;
      } else {
        this.widthValue = "800px";
        this.marginTop = 200;
      }
    },
  },
  components: {
    Vue3Html2pdf,
    CoverContent,
  },
};
</script>
