<template>
  <div style="text-align: center">
    <h4 style="font-size: 32px" class="font-bold">
      <slot name="judul1" />
    </h4>
    <h4 style="font-size: 24px" class="font-bold">
      <slot name="judul2" />
    </h4>
    <h4 style="font-size: 26px; margin-top: 20px" class="font-bold">
      Kelas <slot name="kelas" />
    </h4>
    <h4 style="font-size: 26px" class="font-bold">
      Semester <slot name="semester" />
    </h4>
  </div>
  <table style="width: 100%; margin-top: 30px">
    <tr>
      <td style="text-align: center; width: 33%"></td>
      <td style="text-align: center; width: 33%">
        <img src="../assets/sd.png" alt="" style="padding-left: 0px" />
      </td>
      <td style="text-align: center; width: 33%"></td>
    </tr>
  </table>
  <div class="text-center font-bold mt-3 mb-3">Oleh</div>
  <table style="width: 100%; margin-left: 220px">
    <tr>
      <td style="text-align: left; width: 10%">Nama</td>
      <td style="text-align: center; width: 30px">:</td>
      <td><slot name="nama_lengkap" /></td>
    </tr>
    <tr>
      <td style="text-align: left; width: 10%"><slot name="jenis_nip" /></td>
      <td style="text-align: center; width: 30px">:</td>
      <td><slot name="nip" /></td>
    </tr>
  </table>
  <div style="text-align: center; margin-top: 80px; font-size: 26px">
    <slot name="nama_sekolah" />
  </div>
  <h4
    style="font-size: 24px; margin-bottom: 30px"
    class="font-bold text-center"
  >
    Tahun Ajaran <slot name="tahun_ajaran" />
  </h4>
</template>
<script>
export default {};
</script>
